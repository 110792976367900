import { utils, Utils } from '../../utils';
import window from '@wix/photography-client-lib/dist/src/sdk/windowWrapper';
import TPADimensionsHelper from '@wix/photography-client-lib/dist/src/utils/TPADimensionsHelper';
import { pgVersionManager } from '@wix/photography-client-lib/dist/src/versioning/proGalleryVersionManager';
import VIEW_MODE from 'pro-gallery/dist/es/src/common/constants/viewMode';
import { addPresetStyles } from 'pro-gallery/dist/es/src/components/gallery/presets/presets';
import translationUtils from '@wix/photography-client-lib/dist/src/utils/translationUtils';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import { getProGalleryStyles } from '@wix/photography-client-lib/dist/src/utils/proGalleryStyleBuilder';
import { parseStyleParams } from '@wix/photography-client-lib/dist/src/fullscreen/parseStyleParams';

export default class SiteHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;

    this.update = this.update.bind(this);
    this.parseViewMode = this.parseViewMode.bind(this);
    this.handleNewGalleryStructure = this.handleNewGalleryStructure.bind(this);
    this.getScrollingElement = this.getScrollingElement.bind(this);
    this.getPGStyles = this.getPGStyles.bind(this);
    this.isPremiumSite = this.isPremiumSite.bind(this);
    this.isMobileDevice = this.isMobileDevice.bind(this);
    this.isMobile = this.isMobile.bind(this);
    this.getFullscreenStyles = this.getFullscreenStyles.bind(this);
    this.getStylesForProvider = this.getStylesForProvider.bind(this);
  }

  update(props) {
    this.galleryWrapperProps = props;

    this.updateVersionManagerIfNeeded();

    translationUtils.setTranslations(this.galleryWrapperProps.translations);
    experimentsWrapper.setExperiments({
      ...this.galleryWrapperProps.experiments,
    });

    utils.updateViewMode(this.galleryWrapperProps.viewMode);

    if (window.isSSR) {
      const isMobile = this.isMobileDevice(props.formFactor);
      window.deviceType = isMobile ? 'mobile' : 'desktop';
      utils.setIsWixMobile(isMobile);
    }
  }

  parseViewMode(viewMode) {
    if (this.galleryWrapperProps.isInSEO) {
      return VIEW_MODE.SEO;
    }
    return Utils.parseViewMode(viewMode);
  }
  parseFormFactor(formFactor) {
    return Utils.parseFormFactor(formFactor);
  }

  handleNewGalleryStructure(newGalleryStructureData) {
    const {
      numOfItems,
      container,
      styleParams,
      layoutHeight,
      isInfinite,
      updatedHeight,
    } = newGalleryStructureData;

    const setHeightImp = newHeight => {
      if (typeof this.galleryWrapperProps.setHeight === 'function') {
        if (utils.isVerbose()) {
          console.log('Setting new height for gallery', newHeight);
        }
        this.galleryWrapperProps.setHeight(newHeight);
      }
    };

    TPADimensionsHelper.setWixHeight({
      height: layoutHeight,
      offsetTop: 0,
      styleParams,
      container,
      numOfItems,
      isInfinite,
      updatedHeight,
      setHeightImp,
      viewMode: this.galleryWrapperProps.viewMode,
    });

    try {
      this.galleryWrapperProps.updateLayout();
    } catch (e) {
      console.log('Cannot call updateLayout', e);
    }
  }

  updateVersionManagerIfNeeded() {
    const { dateCreated, gallerySettings } = this.galleryWrapperProps;
    try {
      const _dateCreated = Date.parse(dateCreated);
      if (pgVersionManager.dateCreated !== _dateCreated) {
        if (_dateCreated) {
          pgVersionManager.setDateCreated(_dateCreated);
          if (gallerySettings) {
            let _gallerySettings = gallerySettings;
            if (this.IsJsonString(_gallerySettings)) {
              _gallerySettings = JSON.parse(gallerySettings);
            }
            const galleryUpgrades = _gallerySettings.upgrades;
            pgVersionManager.update(null, galleryUpgrades);
          }
          if (utils.isDev()) {
            window.dateCreated = dateCreated;
          }
        }
      }
    } catch (e) {
      console.error('Could not update version manager', e);
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getScrollingElement() {
    if (typeof this.scrollingElement === 'object') {
      return this.scrollingElement;
    } else if (
      typeof this.galleryWrapperProps.registerToScroll === 'function'
    ) {
      this.scrollingElement = {
        addEventListener: (eventName, callback) => {
          this.galleryWrapperProps.registerToScroll(callback);
        },
        removeEventListener: () => {},
        scrollTo: this.galleryWrapperProps.scrollTo,
      };
      return this.scrollingElement;
    } else {
      return {
        addEventListener: () => {},
        removeEventListener: () => {},
        scrollTo: this.galleryWrapperProps.scrollTo,
      };
    }
  }

  getPGStyles() {
    const { styleParams } = this.galleryWrapperProps.style;
    const isMobile = this.isMobile();
    const { freeArtStore } = this.galleryWrapperProps;
    const pgStyles = {
      ...addPresetStyles(
        getProGalleryStyles(styleParams, {
          isMobile,
          isStoreGallery: this.isStoreGallery && !freeArtStore,
        }),
      ),
      ...this.galleryWrapperProps.manualStyleParams,
      ...this.galleryWrapper.state.manualStyleParams,
    };
    pgStyles.isAccessible = this.galleryWrapper.state.isAccessible;

    if (isMobile && !window.isSSR && typeof pgStyles.gallerySize === 'number') {
      //compensate for wix's 320px fix for mobile
      const fixRatio = 320 / window.screen.width;
      pgStyles.gallerySize *= fixRatio;
      pgStyles.imageMargin *= fixRatio;
    }

    pgStyles.allowLeanGallery = experimentsWrapper.getExperimentBoolean(
      'specs.pro-gallery.allowLeanGallery',
    );

    return pgStyles;
  }

  getStylesForProvider() {
    if (!this.isStoreGallery) {
      return {};
    } else {
      const { styleParams } = this.galleryWrapperProps;
      return styleParams
        ? Object.assign({}, styleParams.colors, styleParams.fonts)
        : {};
    }
  }

  getFullscreenStyles() {
    const props = this.galleryWrapperProps;
    const { styleParams } = this.isStoreGallery ? props : props.style;
    const finalFlatStyles =
      styleParams &&
      (styleParams.numbers ||
        styleParams.colors ||
        styleParams.booleans ||
        styleParams.fonts)
        ? {
            ...styleParams.numbers,
            ...styleParams.colors,
            ...styleParams.booleans,
            ...styleParams.fonts,
            ...this.galleryWrapperProps.manualStyleParams,
          }
        : { ...styleParams, ...this.galleryWrapperProps.manualStyleParams };
    const isMobile = this.isMobile();
    const fullscreenStyles = parseStyleParams(finalFlatStyles, false, isMobile);
    fullscreenStyles.isAccessible = this.galleryWrapper.state.isAccessible;

    return fullscreenStyles;
  }

  isPremiumSite() {
    const { baseUrl } = this.galleryWrapperProps;
    return baseUrl && baseUrl.includes && !baseUrl.includes('.wixsite.com'); // this is a hack until we have a normal way to know
  }

  isMobileDevice() {
    //santa options: mobile/desktop
    //bolt options: smartphone/desktop/tablet
    const deviceType = String(
      this.galleryWrapperProps.formFactor,
    ).toLowerCase();
    return deviceType === 'mobile' || deviceType === 'smartphone';
  }

  isMobile() {
    return this.isMobileDevice() || utils.isMobile();
  }
}
