export const PRO_GALLERY = {
  SENTRY_DSN:
    'https://dcd7075e345b43d991c2ca339000e980@sentry.wixpress.com/112',
  PG_APP_DEFINITION_ID: '14271d6f-ba62-d045-549b-ab972ae1f70e',
  GALLERY_WIDGET_ID: '142bb34d-3439-576a-7118-683e690a1e0d',
  MULTISHARE_WIDGET_ID: '1491fc25-fcd2-9c08-c74f-67b98b69ac4a',
  FULLSCREEN_PAGE_ID: '144f04b9-aab4-fde7-179b-780c11da4f46',
  PG_TEST_APP_DEFINITION_ID: 'f27276e7-0858-460c-a450-4ba27cfe6eea',
  PG_TEST_GALLERY_WIDGET_ID: 'c89921a0-ef16-45a8-931d-e7d3e5a02cdf',
};

export const ART_STORE = {
  ARTSTORE_APP_DEFINITION_ID: '147ab90e-91c5-21b2-d6ca-444c28c8a23b',
  GALLERY_WIDGET_ID: '147ab9e6-2166-63ea-f9f1-3e81db0cbb4a',
  SENTRY_DSN:
    'https://1c9d600630e64cd6b5a4b0cda27df53d@sentry.wixpress.com/113',
  PROVIDERS: {
    LOCAL: '14a0ee97-918b-2f0c-43f1-42e67948716b',
    WIX: '147bec5f-d87e-d37b-b5cf-36f47c1815b4',
    ORDERAPRINT: '14a9911b-a59e-b7f3-f878-3661e0d83f2f',
  },
};

export const ARTSTORE_PUBSUB_ENUMS = {
  SHOW_OOI_CART: 'SHOW_OOI_CART',
  USE_OOI_ARTSTORE: 'USE_OOI_ARTSTORE',
  INIT_CART: 'ARTSTORE_OOI_INIT_CART',
  REFRESH_WORKER_DATA: 'REFRESH_WORKER_DATA',
  GET_WORKER_DATA: 'GET_WORKER_DATA',
  OOI_MESSAGE: 'ARTSTORE_OOI_MESSAGE',
  CART_ITEMS_CHANGE: 'ARTSTORE_OOI_CART_ITEMS_CHANGE',
  REFRESH_OOI_CART: 'ARTSTORE_OOI_REFRESH_CART_ITEMS',
  SHOW_CART: 'ARTSTORE_OOI_SHOW_CART',
  OOI_CHECKOUT: 'ARTSTORE_OOI_CHECKOUT',
  ADD_TO_CART: 'ARTSTORE_OOI_ADD_TO_CART',
  UPDATE_CART_ITEM: 'ARTSTORE_OOI_UPDATE_CART_ITEM',
  OPEN_PROVIDER_MODAL: 'ARTSTORE_OOI_OPEN_PROVIDER_MODAL',
  CLEAR_CART: 'ARTSTORE_OOI_CLEAR_CART',
  UPDATE_VERIFICATION_INSTANCE: 'UPDATE_VERIFICATION_INSTANCE',
  REMOVE_FROM_CART: 'ARTSTORE_OOI_REMOVE_FROM_CART',
  REFRESH_BI_DATA: 'ARTSTORE_OOI_REFRESH_BI_DATA',
  REFRESH_CART: 'REFRESH_CART',
  CHECKOUT_STATE: 'CHECKOUT_STATE',
  CHECKOUT: 'STORE_CHECKOUT',
  CART: 'STORE_CART',
  EDITOR: 'STORE_EDITOR',
  UPDATE_CONNECTED_PROVIDERS: 'UPDATE_CONNECTED_PROVIDERS',
};
