import EVENTS from 'pro-gallery/dist/es/src/common/constants/events';
import FULLSCREEN_EVENTS from '@wix/pro-fullscreen-renderer/dist/src/constants/fullscreenEvents';
import { utils } from '../../utils/utils';

export default class EventHandler {
  constructor(galleryWrapper, props) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.reportedBiGalleryRendered = false;

    this.update = this.update.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.handleFullscreenEvent = this.handleFullscreenEvent.bind(this);
    this.setZeroItems = this.setZeroItems.bind(this);

    this.update(props);
  }

  update(props) {
    this.galleryWrapperProps = props;
    if (
      this.galleryWrapperProps.appLoadStartedReported &&
      this.galleryChangeEventReported
    ) {
      if (utils.isVerbose()) {
        console.log(
          '[APP LOAD DEBUG] galleryWrapper -> eventHandler -> update -> will call onAppLoaded',
        );
      }
      this.galleryWrapper.logHelper.onAppLoaded();
      this.reportedBiGalleryRenderedIfNeeded();
    }
  }

  handleEvent(eventName, eventData) {
    switch (eventName) {
      case EVENTS.LOAD_MORE_CLICKED:
        this.galleryWrapper.loadMoreClicked = true;
        break;
      case EVENTS.ITEM_ACTION_TRIGGERED:
        this.galleryWrapper.itemsHelper.onItemActionTriggered(
          eventData,
          this.galleryWrapper.siteHelper.getPGStyles(),
        );
        break;
      case EVENTS.CURRENT_ITEM_CHANGED:
        this.galleryWrapper.itemsHelper.onCurrentItemChanged(eventData);
        break;
      case EVENTS.GALLERY_CHANGE:
        this.onGalleryChangeEvent();
        this.galleryWrapper.siteHelper.handleNewGalleryStructure(eventData);
        break;
      case EVENTS.NEED_MORE_ITEMS:
        this.galleryWrapper.itemsHelper.getMoreItems(eventData);
        break;
      case EVENTS.SHARE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions('share', eventData);
        this.galleryWrapper.logHelper.reportBiEvent(
          'share',
          eventData,
          'gallery',
        );
        break;
      case EVENTS.TEXT_DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions(
          'downloadTextItem',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'downloadTextItem',
          eventData,
          'gallery',
        );
        break;
      case EVENTS.LOVE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.updateLocalLoveData(eventData.id);
        this.galleryWrapper.itemsHelper.handleItemActions(
          'postLoveActivity',
          eventData,
        );
        if (
          !this.galleryWrapper.itemsHelper
            .getItemActions()
            .isLoved(eventData.id)
        ) {
          //check isLoved before toggleLove action
          //report bi event only if loved, not if unloved
          this.galleryWrapper.logHelper.reportBiEvent(
            'love',
            eventData,
            'gallery',
          );
        }
        this.galleryWrapper.itemsHelper.handleItemActions(
          'toggleLove',
          eventData,
        );
        break;
      case EVENTS.ITEM_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onItemClicked',
          eventData,
          'gallery',
        );
        break;
      case EVENTS.DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onDownloadButtonClicked',
          eventData,
          'gallery',
        );
        break;
      case EVENTS.BUY_NOW_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onBuyNowClicked',
          eventData,
          'gallery',
        );
        break;
      case EVENTS.THUMBNAIL_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onThumbnailClicked',
          eventData,
          'gallery',
        );
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  }

  handleFullscreenEvent(eventName, eventData) {
    switch (eventName) {
      case FULLSCREEN_EVENTS.UPDATE_CURRENT_ITEM:
        this.galleryWrapper.fullscreenHelper.updateFullscreenCurrentItem(
          eventData.item,
        );
        break;
      case FULLSCREEN_EVENTS.CLOSE_FULLSCREEN:
        this.galleryWrapper.fullscreenHelper.animatedCloseFullscreen(
          eventData.itemIdx,
          eventData.animationDuration,
        );
        break;
      case FULLSCREEN_EVENTS.NEED_MORE_ITEMS:
        this.galleryWrapper.itemsHelper.getMoreItems(eventData);
        break;
      case FULLSCREEN_EVENTS.TOGGLE_BROWSER_FULLSCREEN:
        this.galleryWrapper.fullscreenHelper.toggleBrowserFullscreen();
        break;
      case FULLSCREEN_EVENTS.NAVIGATE:
        this.galleryWrapper.itemsHelper.onLinkNavigation(eventData);
        break;
      case FULLSCREEN_EVENTS.FULLSCREEN_LOADED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'item_expanded',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.SHARE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions('share', eventData);
        this.galleryWrapper.logHelper.reportBiEvent(
          'share',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.TEXT_DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.handleItemActions(
          'downloadTextItem',
          eventData,
        );
        this.galleryWrapper.logHelper.reportBiEvent(
          'downloadTextItem',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.DOWNLOAD_BUTTON_CLICKED:
        this.galleryWrapper.logHelper.reportBiEvent(
          'onDownloadButtonClicked',
          eventData,
          'fullscreen',
        );
        break;
      case FULLSCREEN_EVENTS.LOVE_BUTTON_CLICKED:
        this.galleryWrapper.itemsHelper.updateLocalLoveData(eventData.id);
        this.galleryWrapper.itemsHelper.handleItemActions(
          'postLoveActivity',
          eventData,
        );
        if (
          !this.galleryWrapper.itemsHelper
            .getItemActions()
            .isLoved(eventData.id)
        ) {
          //check isLoved before toggleLove action
          //report bi event only if loved, not if unloved
          this.galleryWrapper.logHelper.reportBiEvent(
            'love',
            eventData,
            'fullscreen',
          );
        }
        this.galleryWrapper.itemsHelper.handleItemActions(
          'toggleLove',
          eventData,
        );
        break;
      default:
        if (utils.isVerbose()) {
          console.log(eventName, 'is not handled in handleEvent function');
        }
    }
  }

  onGalleryChangeEvent() {
    this.galleryChangeEventReported = true;
    if (this.galleryWrapperProps.appLoadStartedReported) {
      if (utils.isVerbose()) {
        console.log(
          '[APP LOAD DEBUG] galleryWrapper -> eventHandler -> onGalleryChangeEvent -> will call onAppLoaded',
        );
      }
      this.galleryWrapper.logHelper.onAppLoaded();
      this.reportedBiGalleryRenderedIfNeeded();
    }
  }

  reportedBiGalleryRenderedIfNeeded() {
    //should report only once!
    if (!this.reportedBiGalleryRendered) {
      this.reportedBiGalleryRendered = true;
      if (Math.random() < 0.01 || utils.shouldDebug('gallery_rendered')) {
        // use only 1 of 100 events (do not overflow the bi)
        this.galleryWrapper.logHelper.reportBiEvent('gallery_rendered');
      }
    }
  }

  setZeroItems() {
    this.zeroItems = true;
  }
}
